<template>
<div class="project-export">
  <div class="select-item">
    <div class="title">*{{ $t('project_detail_export_batch') }}</div>
    <div style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:6px;">
      <Checkbox
          :indeterminate="indeterminate"
          v-model="checkAll"
          @click.prevent.native="handleCheckAll">{{ $t('project_detail_export_all') }}</Checkbox>
    </div>
    <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange">
      <Checkbox :label="item.name" v-for="item in projectData.batches" :key="item.id"></Checkbox>
    </CheckboxGroup>
  </div>
  <div class="select-item">
    <div class="title">*{{ $t('project_detail_export_step') }}</div>
    <RadioGroup v-model="batch">
      <Radio :label="item.name" v-for="item in projectData.steps" :key="item.id"></Radio>
    </RadioGroup>
  </div>
  <div class="select-item">
    <div class="title">*{{ $t('project_detail_export_script') }}</div>
    <RadioGroup v-model="packScript">
      <Radio :label="item.name" v-for="item in packScriptList" :key="item.id"></Radio>
    </RadioGroup>
  </div>
  <div class="select-item">
    <div class="title">{{ $t('project_detail_export_config') }}：</div>
    <div class="option">
      <div>{{ $t('project_detail_export_pack_type') }}:</div>
      <RadioGroup v-model="packType">
        <Radio :label="item.name" v-for="item in packTypeList" :key="item.id"></Radio>
      </RadioGroup>
    </div> 
  </div>
  <div class="select-item">
  <Button type="primary" @click="packBuild" :loading="packBuildLoading">{{ $t('project_detail_export_confirm_pack') }}</Button>
  </div>
  <div class="export-tit">
    {{ $t('project_detail_export_export_list') }}
  </div>
  <Table :columns="columns" :data="dataList" :loading="loading"></Table>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import progressOp from '../../components/progress.vue';
import * as XLSX from 'xlsx';

export default {
  name: "projectExport",
  data(){
    return {
      indeterminate: false,
      checkAll: false,
      checkAllGroup: [],
      batch:'', //工序
      packScript:'.word',  //文件格式
      columns: [
        {
          title: this.$t('project_detail_export_pack_id'),
          key: 'id',
          width: 80,
        },
        {
          title: this.$t('project_detail_export_pack_script'),
          key: 'packScript.name',
          render: (h, params) => {
            return h('span', params.row.packScript ? params.row.packScript.name : '');
          },
        },
        {
          title: this.$t('project_detail_export_batch'),
          key:'batchesName',
          tooltip:true,
          // render: (h, params) => {
          //   let batchs = params.row.batches.map((item)=>{
          //     return util.decrypt(item.name)
          //   }).join(',');
          //   return h('span', batchs);
          // },
        },
        {
          title: this.$t('project_step'),
          key: 'step_id',
          width: 100,
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.step.name);
          },
        },
        {
          title: this.$t('project_detail_export_config'),
          key: 'configs',
          render: (h, params) => {

            return h('span', this.packTypeList.filter((item)=>{
              return item.id == params.row.configs;
            })[0].name);
          },
        },
        {
          title: this.$t('project_detail_export_status'),
          key: 'status',
          render: (h, params) => {
            return h('span', this.packStatuses[params.row.pack_status]);
          },
        },
        {
          title: this.$t('project_packaging_progress'),
          key: 'name',
          render: (h, params) => {
            return h('div', [
              h('div', [
                h('Tooltip', {
                  props: {
                    content: '',
                    placement: 'top-start',
                    transfer: true,
                  },
                  scopedSlots: {
                    content: () => {
                      return h('span', {
                      }, [
                        h('div', this.$t('project_successful_jobs_packaged') + '： ' + params.row.pack_item_succ),
                        h('div', this.$t('project_failed_jobs_packaged') + '： ' + params.row.pack_item_fail),
                      ]);
                    }
                  },
                  'class': 'tool_tip',
                  style: {
                    // display: 'inline',
                    width:'100%'
                  }
                }, [
                  h('div', [
                    h(progressOp, {
                      props: {
                        row: params.row,
                      }
                    })
                  ])

                ]),
              ]),
            ]);
          },
        },
        {
          title: this.$t('project_detail_export_file_name'),
          key: 'pack_file',
          width:200,
          render: (h, params) => {
            let texts = '';                                       //表格列显示文字
            if (params.row.pack_file!== null) {
              if (params.row.pack_file.length > 22) {                    //进行截取列显示字数
                texts = params.row.pack_file.substring(0, 22) + "...";
              } else {
                texts = params.row.pack_file;
              }
            }
            return h('Tooltip', {
              props: {
                placement: 'top'
              }
            }, [
              texts,
              h('span', {
                slot: 'content',
                style: {
                  whiteSpace: 'normal',
                  wordBreak: 'break-all'
                }
              }, params.row.pack_file)
            ])
          }
        },
        {
          title: this.$t('project_create_time'),
          key: 'created_at',
          align: 'center',
          width: 120,
          render: (h, para) => {
            return h('Tooltip', {
              props: {
                // content: params.row.name,
                placement: 'top-start',
                transfer: true,
              },
              'class': 'tool_tip',
              scopedSlots: {
                content: () => {
                  return h('span', {
                  }, [
                    h('div', this.$t('project_start_packing_time') + ': ' + util.timeFormatter(
                        new Date(+para.row.pack_start_time * 1000),
                        'yyyy-MM-dd hh:mm'
                    )),
                    h('div', this.$t('project_wrap_up_time') + ': ' + util.timeFormatter(
                        new Date(+para.row.pack_end_time * 1000),
                        'yyyy-MM-dd hh:mm'
                    )),
                  ]);
                }
              },
              style: {
                display: 'inline'
              }
            }, [
              h('span', util.timeFormatter(
                  new Date(+para.row.created_at * 1000),
                  'yyyy-MM-dd hh:mm'
              ))
            ]);
          }
        },
        {
          title: this.$t('project_result'),
          key: 'pack_file',
          ellipsis: true,
          align: 'left',
          render: (h, params) => {
            let btn;
            if(params.row.pack_status == 3){
              btn = h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.downLoad(params.row);
                  }
                }
              }, this.$t('project_detail_export_export'));
            }
            return h('div', [
              btn
            ]);
          },
        },

      ],
      dataList: [],
      projectData:{},
      loading:false,
      packTypeList:[
        {
          id:'0',
          name:this.$t('project_detail_export_one_pack')
        },
        // {
        //   id:'1',
        //   name:this.$t('project_detail_export_packs')
        // },
      ],
      packType:'',
      projectId:'',
      packBuildLoading:false,
      packStatuses:{},
      packScriptList:[],  //打包脚本
      sheetHtml:''
    }
  },
  props:{
    project:{
      type:Object,
      default:{}
    }
  },
  watch:{
    project:{
      handler(newV,oldV){
        if(Object.keys(newV).length){
          this.projectData = newV;
          this.formateData();
        }
      },
      immediate:true,
    }
  },
  created(){
    this.projectId = this.$route.params.id;
    this.initData();  //初始化数据
    this.getPackList(); //获取列表数据
  },
  methods:{
    initData(){
      this.batch = this.projectData.steps && this.projectData.steps.length && this.projectData.steps[0].name;
      this.packType = this.packTypeList[0].name;
      this.getStatusList()
    },
    formateData(){
      this.projectData.batches.forEach((item)=>{
        item.name = util.decrypt(item.name);
      })
    },
    handleCheckAll () {
      if (this.indeterminate) {
        this.checkAll = false;
      } else {
        this.checkAll = !this.checkAll;
      }
      this.indeterminate = false;

      if (this.checkAll) {
        this.checkAllGroup = this.projectData.batches.map((item)=>{
          return item.name;
        });
      } else {
        this.checkAllGroup = [];
      }
    },
    checkAllGroupChange (data) {
      if (data.length === this.projectData.batches.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
    },
    getPackList(){
      let params = {
        project_id:this.projectId
      };
      this.loading = true;
      this.api.production.packList(params).then((res)=>{
        this.loading = false;
        this.dataList = res.list;
        this.dataList.forEach((item)=>{
          item.batchesName = item.batches.map((sItem)=>{
            return util.decrypt(sItem.name)
          }).join(',')
        })
      }).catch((err)=>{
        this.loading = false;
      })
    },
    getStatusList(){

      let params = {
        project_id:this.projectId
      };
      this.api.production.packList(params).then((res)=>{

        this.packStatuses = res.pack_statuses;
        this.packScriptList = res.packScriptList;
        this.packScript = this.packScriptList[0].name;
      })
    },
    packBuild(){
      if(!this.checkAllGroup.length){
        this.$Message.warning(this.$t('project_detail_export_select_batch'));
        return;
      }
      let selectBatches = this.projectData.batches.filter((item)=>{
        return this.checkAllGroup.indexOf(item.name) != -1;
      }).map((item)=>{
        return item.id;
      }).join(',');

      let batchId = this.projectData.steps.filter((item)=>{
        return item.name == this.batch;
      })[0].id;

      let packScript = this.packScriptList.filter((item)=>{
        return item.name == this.packScript;
      })[0].id;

      let packType = this.packTypeList.filter((item)=>{
        return item.name == this.packType;
      })[0].id;

      let params = {
        project_id:this.projectId,
        batch_ids:selectBatches,
        step_id:batchId,
        pack_script_id:packScript,
        configs:packType
      };
      this.packBuildLoading = true;
      this.api.production.packBuild(params).then((res)=>{
        this.packBuildLoading = false;
        this.getPackList();
        this.resetData();
      }).catch((err)=>{
        this.packBuildLoading = false;
      })
    },
    resetData(){  //重置数据
      this.checkAllGroup = [];
      this.checkAll = false;
      this.indeterminate = false;
      this.batch = this.projectData.steps && this.projectData.steps.length && this.projectData.steps[0].name;
      this.packType = this.packTypeList[0].name;
      this.packScript = this.packScriptList[0].name;
    },
    dataURLtoBlob(base64Str) {
       let bstr = atob(base64Str)
      //let bstr = base64Str;
      //console.log(bstr)

      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: 'application/octet-stream' }) //该类型为xls

    },
    downLoad(data){
      let params = {
        project_id:this.projectId,
        pack_id:data.id,
        is_open_online:0,
      };

      this.api.production.packDownload(params).then((res)=>{
        // console.log(res)
        // console.log(res.file)
        // console.log("----3--------")

        let blob = this.dataURLtoBlob(res.file)

        //let blob = res.file
        let fileName = res.filename;
        if (window.navigator.msSaveOrOpenBlob) {
          //兼容 IE & EDGE
          navigator.msSaveBlob(blob, fileName)
        } else {
          var link = document.createElement('a')
          // 兼容不同浏览器的URL对象
          const url = window.URL || window.webkitURL || window.moxURL
          // 创建下载链接
          link.href = url.createObjectURL(blob)

          //console.log(link.href, 'link.href')
          //命名下载名称
          link.download = fileName;
          //点击触发下载
          link.click();
          //下载完成进行释放
          url.revokeObjectURL(link.href);
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.project-export{
  .select-item{
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed #dcdee2;

    .title{
      font-size: 14px;
      margin-bottom: 15px;
      font-weight: bold;
    }
    .option{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >div:nth-child(1){
        margin-right: 10px;
      }
    }
  }
  .export-tit{
    margin: 20px 0;
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
