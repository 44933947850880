import { render, staticRenderFns } from "./projectDetail.vue?vue&type=template&id=09fa7fc1&scoped=true&"
import script from "./projectDetail.vue?vue&type=script&lang=js&"
export * from "./projectDetail.vue?vue&type=script&lang=js&"
import style0 from "./projectDetail.vue?vue&type=style&index=0&id=09fa7fc1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09fa7fc1",
  null
  
)

export default component.exports