<template>
<div class="project-merits">
  <div class="project-merits-top">
    <div>批次统计</div>
<!--    <Button type="primary">导出</Button>-->
  </div>
<!--  <el-table-->
<!--      :data="dataList"-->
<!--      :span-method="arraySpanMethod"-->
<!--      border-->
<!--      @selection-change="handleSelectionChange"-->
<!--      style="width: 100%">-->
<!--    <el-table-column-->
<!--        type="selection"-->
<!--        align="center"-->
<!--        width="55">-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        prop="id"-->
<!--        label="批次ID"-->
<!--        align="center"-->
<!--        width="180">-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        prop="name"-->
<!--        align="center"-->
<!--        label="批次名称">-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        prop="amount"-->
<!--        sortable-->
<!--        align="center"-->
<!--        label="总数量">-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        prop="audit"-->
<!--        sortable-->
<!--        align="center"-->
<!--        label="审核1">-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        prop="audit"-->
<!--        sortable-->
<!--        align="center"-->
<!--        label="审核2">-->
<!--    </el-table-column>-->
<!--  </el-table>-->

  <div class="table-box">
    <table class="batch-table">
        <tr>
          <th><Checkbox v-model="all" @on-change="changeAllCheck"></Checkbox></th>
          <th v-for="(item,index) in batchColumns" :key="index" :style="{width:index == 1 ? '130px' :'auto'}" >
            {{item.title}}
          </th>
        </tr>
        <tr v-for="(item,index) in batchData" :key="item.id">
          <td><Checkbox v-model="item.checkoutStatus" v-if="index < batchData.length - 1" @on-change="changeCheck(item)"></Checkbox></td>
          <td v-for="(sItem,ind) in batchColumns" :key="ind + 'sub'">
            <div v-if="ind > 2">
              <p v-for="(ssItem,sIndex) in item[sItem.key]" :key="sIndex+'ss'">{{ssItem}}
              <span v-if="sIndex == item[sItem.key].length - 1 && index == batchData.length - 1">%</span></p>
            </div>
            <p v-else>{{item[sItem.key]}}</p>
          </td>
        </tr>
    </table>
<!--    <Table border ref="selection" :loading="batchLoading" :columns="batchColumns" :data="batchData" @on-select="addSelection" @on-select-cancel="deleSelection" @on-select-all="addSelection" @on-select-all-cancel="deleSelection"></Table>-->
  </div>

  <div class="project-merits-top mt20">
    <div>人员统计</div>
<!--    <Button type="primary">导出</Button>-->
  </div>
  <div class="search">
    <div class="search-item">
      <div class="name">
        姓名:
      </div>
      <div class="cont">
        <div class="values" >
          <div :class="curUserId == item.user.id ? 'active' : ''" v-for="item in userList" :key="item.user.id + 'name'" @click="changeSearch(item,'curUserId')">{{item.user.nickname}}</div>
        </div>
      </div>
    </div>
    <div class="search-item">
      <div class="name">
        批次:
      </div>
      <div class="cont">
        <div class="values" >
          <div :class="curBatchId == item.id ? 'active' : ''" v-for="item in batchList" :key="item.id + 'batch'" @click="changeSearch(item,'curBatchId')">{{item.name}}</div>
        </div>
      </div>
    </div>
    <div class="search-item">
      <div class="name">
        工序:
      </div>
      <div class="cont">
        <div class="values">
          <div :class="curStepId == item.id ? 'active' : ''" v-for="item in stepList" :key="item.id + 'step'" @click="changeSearch(item,'curStepId')">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
  <Table :loading="loading" :columns="columns" :data="data" style="font-size: 14px;"></Table>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "projectMerits.vue",
  data(){
    return {
      single:false,
      batchColumns:[],
      batchData:[
      ],
      columns:[
        {
          title: '姓名',
          key: 'name',
          render: (h, param) => {
            return h('span', param.row.user.nickname);
          }
        },
        {
          title: '批次ID',
          key: 'name',
          render: (h, param) => {
            return h('span', param.row.batch.id);
          }
        },
        {
          title: '批次名称',
          key: 'name',
          render: (h, param) => {
            return h('span', param.row.task.batch && util.decrypt(param.row.task.batch.name));
          }
        },
        {
          title: '工序名称',
          key: 'name',
          render: (h, param) => {
            return h('span', param.row.task.step && param.row.task.step.name);
          }
        },
        {
          title: '作业量',
          key: 'has_submit_count_byitems',
          render: (h, param) => {
            return h('span', param.row.has_submit_count_byitems);
          }
        },
        // {
        //   title: '提交量',
        //   key: 'submit_count',
        // },
        {
          title: '完成量',
          key: 'work_count'
        },
        {
          title: '驳回量',
          key: 'has_refused_count_byitems'
        },
        {
          title: '驳回率',
          key: 'has_refused_rate_byitems'
        },
        {
          title: '现有驳回量',
          key: 'name',
          render: (h, param) => {
            return h('span', param.row.refused_count - param.row.refused_revise_count);
          }
        },
        {
          title: '现有挂起量',
          key: 'name',
          render: (h, param) => {
            return h('span', param.row.difficult_count - param.row.difficult_revise_count);
          }
        },
      ],
      data:[],
      loading:false,
      batchLoading:false,
      selectionData:[],
      all:false,
      curUserId:'-1',
      userList:[],
      curBatchId:'-1',
      batchList:[],
      curStepId:'-1',
      stepList:[],
    }
  },
  props:{
    project:{
      type:Object,
      default:{}
    }
  },
  watch:{
    project:{
      handler(newV,oldV){
        if(newV.id){
          console.log(newV,'newVnewVnewVnewV')
          this.userList = [
            {
              user: {
                id:"-1",
                nickname:"全部"
              }
            },
            ...newV.taskUsers
          ];
          this.batchList = [
            {
              id:'-1',
              name:'全部'
            },
          ];
          newV.batches.forEach((item)=>{
            item.name = util.decrypt(item.name);
            this.batchList.push(item);
          })
          this.stepList = [
            {
              id:'-1',
              name:'全部'
            },
            ...newV.steps
          ];
          this.getUserStat();
          this.getBatchStat();
        }
      },
      immediate:true,
    }
  },
  methods:{
    changeAllCheck(){
      if(this.all){
        this.batchData.forEach((item)=>{
          item.checkoutStatus = true;
        })

      }else{
        this.batchData.forEach((item)=>{
          item.checkoutStatus = false
        })
      }
      this.selectionData = this.batchData.filter((item)=>{
        return item.checkoutStatus;
      });
      this.formateTotal();
    },
    changeCheck(data){
      this.selectionData = this.batchData.filter((item)=>{
        return item.checkoutStatus;
      });
      if(this.selectionData.length == this.batchData.length - 1){
        this.all = true;
      }else{
        this.all = false;
      }

      this.formateTotal();
    },

    formateTotal(){
      //过滤掉合计
      let totalData = this.selectionData.filter((item)=>{
        return item.name;
      });
      for(let name in this.batchData[this.batchData.length -1]){
        if(name != 'id' && name != 'name'){
          let arr = totalData.map((item)=>{
            return item[name];
          });
          if(name.indexOf('index') != -1){
            this.batchData[this.batchData.length -1][name] = this.sumData(arr) ;
          }else{
            this.batchData[this.batchData.length -1][name] = this.sumData(arr).join(' , ');
          }
        }
      }
    },
    sumData(data){  //合计
      let total = [];
      let finishData;
      data.forEach((item)=>{
        if(!item) return;
        console.log(typeof item,'dddd')
        if(typeof item == 'string'){
          if(!total[0]){
            total[0] = 0;
          }
          total[0] += parseFloat(item);
          finishData = total;
        }else{
          item.forEach((sItem,index)=>{
            console.log(sItem,'sItemsItemsItem')
            let arr = sItem.split(': ');

            if(!total[index]){
              total[index] = {
                name:arr[0],
                value:0
              };
            }

            if(arr[1]){
              total[index].value += parseFloat(arr[1]);
            }else{
              total[index].value += parseFloat(arr[0]);
            }
          })
          finishData = total.map((item)=>{
            return item.name + ':' + item.value;
          })
        }

      })

      return finishData;
    },
    getBatchStat(){
      let params = {
        project_id:this.$route.params.id
      };
      this.batchLoading = true;
      this.api.production.projectStatListByBatch(params).then((res)=>{
        this.batchLoading = false;
        this.batchColumns = this.formateColumn(res.project.steps);
        this.batchData = this.formateData(res.project.batches, res.project.steps);

      }).catch((err)=>{
        this.batchLoading = false;
      })
    },
    formateColumn(data) {
      let arr = [];
      data.forEach((item,index)=>{
        // arr.push({
        //   title: item.name + '(次)',
        //   key: 'index' + index
        // })
        arr.push({
          title: item.name,
          key: 'index1' + index
        })
      })
      let column = [
        {
          title: '批次ID',
          key: 'id'
        },
        {
          title: this.$t('project_create_personnel_batch_name'),
          key: 'name'
        },
        {
          title: this.$t('project_create_personnel_batch_num'),
          key: 'amount'
        },
        ...arr,
      ];
      return column;
    },
    formateData(data, steps) {
      data.forEach((item) => {
        item.name = util.decrypt(item.name);
        this.$set(item,'checkoutStatus',false);

        if (item.tasks.length){

          steps.forEach((sItem, index) => {
            // let has_refuse_rate_bytimes = item.tasks.filter((task) => {
            //   return task.step_id == sItem.id;
            // })[0].stat.has_refuse_rate_bytimes;
            // let refused_count = item.tasks.filter((task) => {
            //   return task.step_id == sItem.id;
            // })[0].stat.refused_count;
            //
            // item['index' + index] = refused_count  + ' , ' + has_refuse_rate_bytimes;

            let has_refused_count = item.tasks.filter((task) => {
              return task.step_id == sItem.id;
            })[0].stat.has_refused_count;

            let has_difficult_count = item.tasks.filter((task) => {
              return task.step_id == sItem.id;
            })[0].stat.has_difficult_count;

            let has_refuse_submited_count = item.tasks.filter((task) => {
              return task.step_id == sItem.id;
            })[0].stat.has_refuse_submited_count;

            let has_refuse_wait_count = item.tasks.filter((task) => {
              return task.step_id == sItem.id;
            })[0].stat.has_refuse_wait_count;



            let has_execute_count = item.tasks.filter((task) => {
              return task.step_id == sItem.id;
            })[0].stat.has_execute_count;

            let has_refused_count_byitems = item.tasks.filter((task) => {
              return task.step_id == sItem.id;
            })[0].stat.has_refused_count_byitems;

            let has_refused_rate_byitems = item.tasks.filter((task) => {
              return task.step_id == sItem.id;
            })[0].stat.has_refused_rate_byitems;

            let work_count = item.tasks.filter((task) => {
              return task.step_id == sItem.id;
            })[0].stat.work_count;


            item['index1' + index] =  [
              '可领取: ' + has_execute_count ,
              '被驳回: ' + has_refused_count,
              '挂起: '+ has_difficult_count,
              '已返工: '+ has_refuse_submited_count,
              '待返工: ' + has_refuse_wait_count,
              '已完成: ' + work_count,
              '一次驳回数: ' + has_refused_count_byitems,
              '一次驳回率: ' + has_refused_rate_byitems
            ];
          })
        }
      });
      let totalRow = {
        id:'合计',
        name:'',
        amount:'',
      }
      steps.forEach((sItem, index) => {
        totalRow['index' + index] = 0;
        totalRow['index1' + index] = 0;
      })
      data.push(totalRow)
      return data;
    },
    getUserStat(){
      let params = {
        project_id:this.$route.params.id,
        batch_id:this.curBatchId,
        step_id:this.curStepId,
        user_id:this.curUserId,
      };
      this.loading = true;
      this.api.production.projectStatUsers(params).then((res)=>{
        this.loading = false;
        this.data = res.list;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeSearch(data,name){
      if(name == 'curUserId'){
        this[name] = data.user.id;
      }else{
        this[name] = data.id;
      }

      this.page = 1;
      this.getUserStat();
    },
  }
}
</script>

<style scoped lang="scss">
.project-merits{
  margin-top: 20px;
  font-size: 14px;

  .project-merits-top{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .mt20{
    margin-top: 40px;
  }
  .table-box{
    //max-height: 500px;
    //overflow-y: auto;
  }
  .batch-table{
    width: 100%;
    border: 1px solid #dcdee2;
    font-size: 12px;

    >tr{
      height: 38px;
      line-height: 38px;
      border-bottom: 1px solid #dcdee2;

      >td,>th{
        border-right: 1px solid #dcdee2;
      }
      >td:last-child,>th:last-child{
        border-right: none;
      }
      >td{
        line-height: 20px;
      }
    }
    >tr:last-child{
      border-bottom: none;
    }

  }
}
</style>
