<template>
<div class="project-record">
  <div class="search">
    <div>
      <p>操作时间</p>
      <DatePicker v-model="params.date" :options="dateOptions" type="datetimerange" placeholder="Select date" format="yyyy-MM-dd" style="width: 200px;"></DatePicker>
    </div>
    <div>
      <p>搜索内容</p>
      <Input v-model="params.cont" placeholder="Enter something..."  />
    </div>
    <div class="btn">
      <Button class="mr20" @click="resetParam">重置</Button>
      <Button type="primary" @click="search">查询</Button>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: "projectRecord",
  data(){
    return {
      params:{
        date:[],
        cont:'',
      },
      projectData:{},
      dateOptions:{}, //配置日期的最大时间和最小时间
    }
  },
  props:{
    project:{
      type:Object,
      default:{}
    }
  },
  watch:{
    project:{
      handler(newV,oldV){
        if(Object.keys(newV).length){
          this.projectData = newV;
          let data = this.projectData;
          this.dateOptions = {
            disabledDate (date) {
              let disabled = false;
              if(date.getTime() > Number(data.end_time) * 1000 || date.getTime() < Number(data.start_time) * 1000){
                disabled = true;
              }
              return disabled;
            }
          }
        }

      },
      immediate:true,
    }
  },
  methods:{
    resetParam(){ //重置

    },
    search(){ //查询

    }
  }
}
</script>

<style scoped lang="scss">
.project-record{
  .search{
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >div{
      margin-left: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        white-space: nowrap;
        margin-right: 10px;
      }
    }
    .btn{

    }
  }
}
</style>
