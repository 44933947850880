<template>
    <div>
    <span>
<!--        <p><Button type="primary" size="small" style="margin: 3px" @click="settingUser">{{$t('admin_settings')}} ({{user_count}})</Button></p>-->
        <p><Button :type="showColorByNumber()" size="small" style="margin: 3px" v-if="step_type == '0' && !unShow" @click="execute">{{$t('operator_execute')}} {{showNumber()}}</Button></p>
        <p><Button :type="showColorByNumber()" size="small" style="margin: 3px" v-if="step_type == '1' && !unShow" @click="execute">{{$t('operator_audit')}} {{showNumber()}}</Button></p>
        <p><Button :type="showColorByNumber()" size="small" style="margin: 3px" v-if="step_type == '2' && !unShow" @click="execute">{{$t('operator_qc')}}{{showNumber()}}</Button></p>
        <p><Button :type="showColorByNumber()" size="small" style="margin: 3px" v-if="step_type == '3' && !unShow" @click="execute">{{$t('task_quality')}}{{showNumber()}}</Button></p>
<!--      驳回作业  -->
        <p><Button type="warning" size="small" style="margin: 3px" v-if="has_refused_count * 1 > 0" @click="toPage('refused')">{{$t('admin_rejected_work')}} ({{has_refused_count}})</Button></p>
<!--      挂起作业  -->
        <p><Button type="warning" size="small" style="margin: 3px" v-if="has_difficult_count * 1 > 0" @click="toPage('difficult')">{{$t('admin_difficult_work')}} ({{has_difficult_count}})</Button></p>
<!--      返工作业-->
        <!-- <p><Button type="warning" size="small" style="margin: 3px" v-if="has_refuse_count * 1 > 0" @click="toPage('refuse_submit')">{{$t('admin_rework_work')}} ({{has_refuse_count}})</Button></p> -->
<!--      已返工作业-->
        <p><Button type="warning" size="small" style="margin: 3px" v-if="has_refuse_submited_count * 1 > 0" @click="toPage('refuse_submit')">已返工 ({{has_refuse_submited_count}})</Button></p>
<!--      待返工作业-->
        <p><Button type="warning" size="small" style="margin: 3px" v-if="has_refuse_wait_count * 1 > 0" @click="toPage('refuse_wait')">待返工 ({{has_refuse_wait_count}})</Button></p>

    </span>
    </div>
</template>

<script>
export default {
    name: 'task-management-op',
    props: {
        project_id: {
            type: String,
            required: true
        },
        task_id: {
            type: String,
            required: true
        },
        step_type: {
            type: String,
            required: true
        },
        file_type: {
            type: String,
            //required: true
        },
        has_refused_count: {
            type: String,
            required: true
        },
        has_refuse_submited_count: {
            type: String,
            required: true
        },
        has_refuse_wait_count: {
            type: String,
            required: true
        },
        has_difficult_count: {
            type: String,
            required: true
        },
        has_refuse_count: {
            type: String,
            required: true
        },
        user_count: {
            type: String,
            required: true
        },
        has_execute_count: {
            type: String,
            required: true
        },
        unShow:{
          type:Boolean,
          default:false
        }
    },
    data () {
        return {};
    },
    methods: {
        showColorByNumber(){
            return this.has_execute_count * 1 > 0 ? "primary" : "default"
        },
        showNumber(){
            return this.has_execute_count * 1 > 0 ? "("+this.has_execute_count+")" : ""
        },
        // {{ (has_execute_count * 1 > 0 ? 'primary' : 'black') }}
        settingUser () {
            this.$router.push({
                name: 'set-task-user',
                params: {
                    task_id: this.task_id
                }
            });
        },
        textAuditBatch () {
            this.$router.push({
                name: 'perform-batch-audit',
                query: {
                    project_id: this.project_id,
                    task_id: this.task_id
                }
            });
        },
        execute () {
          console.log('task-execute')
            this.$router.push({
                //name: 'perform-task',
                name: 'task-execute',
                query: {
                    project_id: this.project_id,
                    task_id: this.task_id
                }
            });
        },
        toPage (index) {
          if(this.unShow){
            this.$emit('goWork',{
              taskId:this.task_id,
              tab: 'work-list',
              subtab: index,
            })
          }else{
            this.$router.push({
              name: 'task-detail',
              params: {
                id: this.task_id,
                tab: 'work-list',
                subtab: index,
              }
            });
          }

        },
    }
};
</script>

<style scoped>
</style>
