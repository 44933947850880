<template>
  <div>
    <div class="role-main" v-if="!showWork">
      <div class="search">
        <div class="search-item" v-if="curProjectId != -1">
          <div class="name">
            批次名称:
          </div>
          <div class="cont">
            <!--          <div class="values" :style="{'max-height':batchMoreStatus ? '80px' : '37px'}">-->
            <div class="values">
              <div :class="curBatchId == item.id ? 'active' : ''" v-for="item in batchList" :key="item.id + 'batch'" @click="changeSearch(item,'curBatchId')">{{item.name}}</div>
            </div>
            <!--          <div class="more" v-if="batchList.length > 5" @click="more('batchMoreStatus')">-->
            <!--            <Icon type="ios-arrow-down" class="more-icon" :style="{'transform': batchMoreStatus ? 'rotate(180deg)' : 'rotate(0deg)'}" />-->
            <!--            更多-->
            <!--          </div>-->
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            工序名称:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="curStepId == item.id ? 'active' : ''" v-for="item in stepList" :key="item.id + 'step'" @click="changeSearch(item,'curStepId')">{{item.name}}</div>
            </div>

          </div>
        </div>
<!--        <div class="search-item">-->
<!--          <div class="name">-->
<!--            领取状态:-->
<!--          </div>-->
<!--          <div class="cont">-->
<!--            <div class="values">-->
<!--              <div :class="curReceiveId == item.id ? 'active' : ''" v-for="item in receiveStatuses" :key="item.id + 'receive'" @click="changeSearch(item,'curReceiveId')">{{item.name}}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>

      <Table border :columns="columns" :loading="loading" :data="data"></Table>
      <div class="page">
        <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>

    </div>
    <WorkList v-else :taskId="taskId" :taskInfo="taskInfo" :tab="tab" :subTab="subtab" :projectId="curProjectId" :unshow="true" @back="showWork = false;"></WorkList>
  </div>

</template>

<script>
import DeleModal from '@/components/deleteModal.vue';
import taskOp from '../../../task/components/tasks/task-op.vue';
import progressOp from '../../../task/components/tasks/progress.vue';
import WorkList from './workList.vue';
import util from "@/utils/tools";
export default {
  name: "role",
  data(){
    return{
      columns: [
        {
          title: this.$t('task_table_taskid'),
          key:'id'
        },
        {
          title: this.$t('task_table_project'),
          key: 'project_id',
          minWidth: 60,
          render: (h, para) => {
            // console.log(para.row.id,'para.row.id')
            return h('div', {
              on: {
                click:()=>{
                  // cosole.log('22222')
                  this.taskId = para.row.id;
                  this.taskInfo = para.row;
                  this.subtab = 'all';
                  this.showWork = true;
                }
              },
              style:{
                color:'#2db7f5',
                cursor:'pointer'
              }
            }, para.row.project.name);
          },
        },
        {
          title: this.$t('task_table_batch'),
          key: 'batch_id',
          minWidth: 60,
          render: (h, para) => {
            return h("span", util.base64decode(para.row.batch.name) + '('+para.row.batch.id+')');
          },
        },
        {
          title: this.$t('task_table_step'),
          key: "step_type",
          align: "center",
          render: (h, para) => {
            return h("span", para.row.step.name);
          },
        },
        {
          title: this.$t('task_table_type'),
          key: "category_id",
          align: "center",
          maxWidth: 100,
          render: (h, para) => {
            return h("span", para.row.project.category.name);
          },
        },
        {
          title: this.$t('task_table_amount'),
          key: "amount",
          align: "center",
          maxWidth: 170,
          minWidth: 120,
          render: (h, para) => {
            if (para.row.stat) {
              return h("span", (para.row.stat.work_count ? para.row.stat.work_count : '0') + ' / ' + (para.row.stat.amount ? para.row.stat.amount : 0) + ' / ' + (para.row.batch.amount ? para.row.batch.amount : 0));
            } else {
              return h("span", '-- / ' + '-- / ' + (para.row.batch.amount ? para.row.batch.amount : 0));
            }
          },
          renderHeader: (h, params) => {
            return h('span', [
              h('Poptip', {
                'class': {
                  tablePop: true,
                },
                props: {
                  trigger: "hover",
                  title: this.$t('admin_fields_description'),
                  content: "",
                  transfer: true,
                  placement: 'right-start',
                },
                scopedSlots: {
                  content: () => {
                    return h('span', {
                    }, [
                      h('div', this.$t('admin_execute_description')),
                      h('div', this.$t('admin_placeholder_description')),
                    ]);
                  }
                }
              }, [
                h('span', this.$t('admin_working_out')),
                h('Icon', {
                  style: {
                    marginLeft: '3px',
                    marginTop: '1px',
                    verticalAlign: 'top'
                  },
                  props: {
                    type: 'ios-help-circle-outline',
                    size: 16
                  },
                }),

              ])
            ]);
          }
        },
        {
          title: this.$t('task_table_progress'),
          key: "statistics",
          align: "left",
          maxWidth: 230,
          minWidth: 50,
          render: (h, para) => {
            return h('div', [
              h('div', [
                h('Tooltip', {
                  props: {
                    content: (para.row.stat.work_count ? para.row.stat.work_count : '0') + ' / ' + (para.row.stat.amount ? para.row.stat.amount : 0) + ' / ' + (para.row.batch.amount ? para.row.batch.amount : 0),
                    placement: 'top-start',
                    transfer: true
                  },
                  'class': 'tool_tip',
                  style: {
                    width: '100%'
                  }
                }, [
                  h('div', [
                    h(progressOp, {
                      props: {
                        row: para.row,
                      }
                    })
                  ])

                ]),
              ]),
            ]);
          },
          renderHeader: (h, params) => {
            return h('span', [
              h('Poptip', {
                'class': {
                  tablePop: true,
                },
                props: {
                  trigger: "hover",
                  title: this.$t('admin_fields_description'),
                  transfer: true,
                  placement: 'right-start',
                },
                scopedSlots: {
                  content: () => {
                    return h('span', {
                    }, [
                      h('div', this.$t('admin_execute_description')),
                    ]);
                  }
                }
              }, [
                h('span', this.$t('admin_schedule')),
                h('Icon', {
                  style: {
                    marginLeft: '3px',
                    marginTop: '1px',
                    verticalAlign: 'top'
                  },
                  props: {
                    type: 'ios-help-circle-outline',
                    size: 16
                  },
                }),
              ])
            ]);
          }
        },
        {
          title: this.$t('task_table_endtime'),
          key: 'end_time',
          align: 'center',
          maxWidth: 120,
          minWidth: 40,
          render: (h, params) => {
            return h('Tooltip', {
              props: {
                placement: 'top-start',
                transfer: true
              },
              scopedSlots: {
                content: () => {
                  return h('span', {}, [
                    h('div', this.$t('admin_start_time') + ': ' + util.timeFormatter(new Date(+params.row.start_time * 1000), 'yyyy-MM-dd hh:mm')),
                    h('div', this.$t('admin_end_time') + ': ' + util.timeFormatter(new Date(+params.row.end_time * 1000), 'yyyy-MM-dd hh:mm')),
                  ]);
                }
              },
              style: {
                display: 'inline'
              }
            }, [
              h('span', util.timeFormatter(new Date(+params.row.end_time * 1000), 'yyyy-MM-dd hh:mm'))
            ]);
          },
          sortable: 'custom',
        },
        {
          title: this.$t('task_table_operation'),
          align: "left",
          maxWidth: 130,
          render: (h, para) => {
            let _this = this;
            return h('div', [
              h(taskOp, {
                props: {
                  project_id: para.row.project_id,
                  task_id: para.row.id,
                  step_type: para.row.step.type,
                  user_count: para.row.user_count,
                  has_refused_count: para.row.stat.has_refused_count,
                  has_refuse_submited_count: para.row.stat.has_refuse_submited_count,
                  has_refuse_wait_count: para.row.stat.has_refuse_wait_count,
                  has_difficult_count: para.row.stat.has_difficult_count,
                  has_refuse_count: para.row.stat.has_refuse_count,
                  has_execute_count: para.row.stat.has_execute_count,
                  unShow:true,
                },
                on:{
                  goWork(data){
                    console.log(data,'dddddd',_this,'wwww')
                    _this.taskId = data.taskId;
                    _this.taskInfo = para.row;
                    _this.tab = data.tab;
                    _this.subtab = data.subtab;
                    _this.showWork = true;
                  }
                }
              })
            ]);
          },
          renderHeader: (h, params) => {
            return h('span', [
              h('Poptip', {
                props: {
                  trigger: "hover",
                  title: this.$t('admin_fields_description'),
                  transfer: true,
                  placement: 'right-start',
                },
                scopedSlots: {
                  content: () => {
                    return h('span', {}, [
                      //h('div', this.$t('admin_setting_members')),
                      h('div', this.$t('task_tasks_operation_execute_tip')),
                      h('div', this.$t('task_tasks_operation_refuse_tip')),
                      h('div', this.$t('task_tasks_operation_refused_tip')),
                      h('div', this.$t('task_tasks_operation_difficute_tip')),
                    ]);
                  }
                }
              }, [
                h('span', this.$t('admin_handle')),
                h('Icon', {
                  style: {
                    marginLeft: '3px',
                    marginTop: '1px',
                    verticalAlign: 'top'
                  },
                  props: {
                    type: 'ios-help-circle-outline',
                    size: 16
                  },
                }),
              ])
            ]);
          }
        }
      ],
      data: [],
      loading:false,
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      page:1,
      pageSize:10,
      total:0,
      modalStatus:false,
      formItem:{
        id:'',
        name:'',
        key:'',
        value:'',
        desc:'',
      },
      ruleValidate: {
        id: [
          { required: true, message: this.$t('task_add_id_tip'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('task_add_name_tip'), trigger: 'blur' }
        ],
        key: [
          { required: true, message: this.$t('task_add_key_tip'), trigger: 'blur' }
        ],
        value: [
          { required: true, message: this.$t('task_add_value_tip'), trigger: 'blur' }
        ],
        desc: [
          { required: true, message: this.$t('task_add_desc_tip'), trigger: 'blur' },
        ],
      },
      addBtnLoading:false,
      type:'',
      modalTit:'',
      step_types: {},
      categories: [],
      projectMoreStatus:false,
      batchMoreStatus:false,
      categoryList:[],  //项目类型
      curCategoryId:'-1',
      projectList:[], //项目名称
      curProjectId:'-1',
      batchList:[], //批次名称
      curBatchId:'-1',
      stepList:[],  //工序名称
      curStepId:'-1',
      receiveStatuses:[], //领取状态
      curReceiveId:'1',
      taskId:'',
      taskInfo:'',
      tab:'',
      subtab:'',
      showWork:false,
    }
  },
  components:{
    DeleModal,
    taskOp,
    progressOp,
    WorkList
  },
  mounted(){
    // console.log('23232323')
    this.curProjectId = this.$route.params.id;
    this.getList();
  },
  methods:{
    more(name){ //更多
      this[name] = !this[name];
    },
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        show_allow_receive:this.curReceiveId,
        category_type:this.curCategoryId,
        project_id:this.curProjectId,
        batch_id:this.curBatchId,
        step_type:this.curStepId,
      };
      this.api.task.taskList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        this.step_types = res.step_types;
        // this.categoryList = [
        //   {
        //     name:'全部',
        //     id:'-1'
        //   },
        //   ...res.categoryList
        // ];
        // this.projectList = [
        //   {
        //     name:'全部',
        //     id:'-1'
        //   },
        //   ...res.projectList
        // ];
        let batchList = res.batchList.map((item)=>{
          item.name =  util.base64decode(item.name);
          return item;
        })
        this.batchList = [
          {
            name:'全部',
            id:'-1'
          },
          ...batchList
        ];
        console.log(this.batchList,'this.batchList')
        this.stepList = [{
          name:'全部',
          id:'-1'
        }];
        for(let name in res.step_types){
          this.stepList.push({
            name:res.step_types[name],
            id:name,
          })
        }
        this.receiveStatuses = [{
          name:'全部',
          id:'-1'
        }];
        for(let name in res.receiveStatuses){
          this.receiveStatuses.push({
            name:res.receiveStatuses[name],
            id:name,
          })
        }
        //this.categories = res.categories;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      // console.log(page,'changePage')
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    changeSearch(data,name){
      this[name] = data.id;
      if(name == 'curCategoryId'){
        this.curProjectId = '-1';
        this.curBatchId = '-1';
      }
      if(name == 'curProjectId'){
        this.curBatchId = '-1';
      }
      this.getList();
    }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin-top: 20px;
  .search{
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }

  .top{
    text-align: left;
    margin-bottom: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
