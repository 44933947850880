<template>
  <div class="work-list">
    <div style="margin-bottom: 20px">
      <Button @click="back">&lt; 返回</Button>
    </div>
    <div style="margin-bottom: 20px">
      <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
        <i-col span="4">项目ID :   {{projectId}}</i-col>
        <i-col span="6">工序 :  {{taskInfo.step.name}}({{taskInfo.step.id}})</i-col>
        <i-col span="8">批次 :   {{ taskInfo.batch.name}}({{taskInfo.batch.id}})</i-col>
        <i-col span="6">任务ID :   {{taskId}}</i-col>
      </Row>
    </div>
    <div class="top">
      <div class="tab">
        <div :class="item.id == curSubTab ? 'active' : ''" v-for="item in subTabList" :key="item.id" @click="changeSubTab(item)">
          <div >{{ item.name }}</div>
        </div>

      </div>

    </div>
    <div class="table">
      <div class="work">
        <div class="search">
          <!--    <div class="search-type" v-if="op == '5'">-->
          <!--      <span>任务类型</span>-->
          <!--      <Select v-model="invalid" style="width:200px">-->
          <!--        <Option v-for="item in invalidList" :value="item.value" :key="item.value">{{ item.label }}</Option>-->
          <!--      </Select>-->
          <!--    </div>-->
          <Input suffix="ios-search" v-model="keyword" search :placeholder="$t('task_detail_placeholder')" style="width: auto" @on-search="getList" />
        </div>
        <div class="table">
          <Table :columns="columns" :data="dataList" :loading="loading"></Table>
        </div>

        <div class="page">
          <Page :total="total" :current="page" @on-change="changePage" show-total />
        </div>
        <Modal
            v-model="resultModal"
            :title="$t('project_detail_data_work_result')"
            width="70%"
        >
          <ResultView :resultData="resultData" v-if="resultDataLen"></ResultView>
          <div slot="footer"></div>
        </Modal>
        <Modal
            v-model="recordModal"
            :title="$t('project_detail_data_op_record')"
            width="70%"
        >
          <Record :recordData="recordData"></Record>
          <div slot="footer"></div>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import ResultView from '@/components/production/category_question/view.vue'
import Record from '@/components/production/category_question/record.vue'

export default {
  name: "workList",
  data(){
    return{
      columns: [
        {
          title: this.$t('task_detail_work_id'),
          key: 'data_id'
        },
        {
          title: this.$t('task_detail_work_status'),
          render:(h,params) =>{
            return h('span',this.statusesList[params.row.status])
          }
        },
        {
          title: '操作员',
          render:(h,params) =>{
            return h('span', params.row.user ? params.row.user.nickname + '('+params.row.user.id+')' : '')
          }
        },
        {
          title: this.$t('task_detail_date'),
          render:(h, params) => {
            return h('div', {},util.timeFormatter(
                new Date(+params.row.updated_at * 1000),
                'yyyy-MM-dd hh:mm:ss'
            ));
          }
        },
        {
          title: this.$t('task_detail_check_result'),
          key: 'address',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  loading:params.row.resultLoading
                },
                on: {
                  click: () => {
                    this.showResult(params.row)
                  }
                }
              }, this.$t('project_detail_data_result'))
            ]);
          }
        },
        {
          title: this.$t('task_detail_op'),
          render: (h, para) => {
            //3 已提交 6 被驳回 7 挂起中 8 待复审
            let op;
            let btn;
            let btnType;

            console.log(this.unshow,para.row.status,'para.row.status')

            if(this.unshow && (para.row.status === "1" || para.row.status === "3" || para.row.status === "4" || para.row.status === "6"|| para.row.status === "7")){
              op = 'force_reset';
              btn = '强制重置';
              btnType = 'warning';
            }
            console.log(this.curSubTab,'this.curSubTab.idthis.curSubTab.id')
            if(this.unshow &&  this.curSubTab == 'refuse_submit'){
              op = 'force_reset';
              btn = '强制重置';
              btnType = 'warning';
            }

            // 未领取
            // if (para.row.status === "0") {
            //   op = 'fetch';
            //   btn = this.$t('task_detail_worklist_fetch');
            //   btnType = 'warning';
            //   // 已领取 执行中
            // } else if (para.row.status === "1" || para.row.status === '2') {
            //   op = 'fetch';
            //   btn = this.$t('task_detail_worklist_working');
            //   btnType = 'warning';
            //
            //   //已提交
            // } else if (para.row.status === '3' && para.row.is_refused !== '1' && para.row.is_refused !== '2') {
            //   op = 'redo';
            //   btn = this.$t('task_detail_worklist_redo');
            //   btnType = 'warning';
            // } else if (para.row.status === '6') { //被驳回
            //   if(this.unshow){
            //     op = 'force_reset';
            //     btn = '强制重置';
            //     btnType = 'warning';
            //   }else{
            //     op = 'refused_revise';
            //     btn = this.$t('task_detail_worklist_refused_revise'); //修改
            //     btnType = 'warning';
            //   }
            //
            // } else if (para.row.status === '7') { //挂起中
            //   if(this.unshow){
            //     op = 'force_reset';
            //     btn = '强制重置';
            //     btnType = 'warning';
            //     console.log('2333333')
            //   }else{
            //     console.log('4444')
            //     op = 'difficult_revise';
            //     // stepType 0 执行 1 审核  2 质检 3 验收
            //     btn = this.$t('task_detail_worklist_difficult_revise'); // 需要判断
            //     btnType = 'primary';
            //   }
            //
            // } else if (para.row.status === '8') { //待复审
            //   op = 'refusesubmit_receive';
            //   btn = this.$t('task_detail_worklist_refusesubmit_receive');
            //   btnType = 'primary';
            // }
            console.log(btn,op)

            return h('div', [
              op && btn ? h('Button',
                  {
                    props: {
                      type: btnType,
                      size: 'small',
                    },
                    style: {
                      margin: '5px'
                    },
                    on: {
                      click: () => {
                        this.operatorDataId = para.row.data.id;
                        this.curProjectId = para.row.project_id;
                        this.userId = para.row.user && para.row.user.id;
                        console.log(this.curSubTab.id,'this.curSubTab.idthis.curSubTab.id')
                        if(this.unshow && (para.row.status == '1' || para.row.status === "3" || para.row.status === "4" || para.row.status == '6' || para.row.status == '7' || this.curSubTab == 'refuse_submit')){
                          this.forceReset(op);
                        }else{
                          this.taskExecute(op);
                        }

                      }
                    }
                  }, btn) : '',
              h('Button',
                  {
                    props: {
                      size: 'small'
                    },
                    style: {
                      margin: '5px'
                    },
                    on: {
                      click: () => {
                        this.showRecord(para.row)
                      }
                    }
                  }, this.$t('operator_record')),
            ]);
          }
        }
      ],
      dataList: [],
      total:0,
      page:1,
      pageSize:10,
      op:'0',
      // taskId:'',
      loading:false,
      statusesList:{},
      resultModal:false,
      resultLoading:false,
      resultData:{},
      recordModal:false,  //操作记录弹窗
      recordData:{},
      recordLoading:false,
      keyword:'',
      userId:'',
      curProjectId:'',
      operatorDataId:'',

      invalidList: [
        {
          value: 'New York',
          label: 'New York'
        },
        {
          value: 'London',
          label: 'London'
        },
        {
          value: 'Sydney',
          label: 'Sydney'
        },
        {
          value: 'Ottawa',
          label: 'Ottawa'
        },
        {
          value: 'Paris',
          label: 'Paris'
        },
        {
          value: 'Canberra',
          label: 'Canberra'
        }
      ],  //失效列表
      invalid: '',
      subTabList:[
        {
          name:'全部作业',
          id:'all',
          // componentName:'WorkAll'
        },
        {
          name:'待领取',
          id:'new',
          // componentName:'WorkUnclaimed'
        },
        {
          name:'作业中',
          id:'working',
          // componentName:'WorkOperation'
        },
        {
          name:'已提交',
          id:'submited',
          // componentName:'WorkSubmit'
        },
        {
          name:'已通过',
          id:'finish',
          // componentName:'WorkReceived'
        },
        {
          name:'已失效',
          id:'invalid',
          // componentName:'WorkExpired'
        },
        {
          name:'待修改',
          id:'refused',
          // componentName:'WorkModified'
        },
        {
          name:'挂起',
          id:'difficult',
          // componentName:'WorkPending'
        },
        {
          name:'已返工',
          id:'refuse_submit',
          // componentName:'WorkRework'
        },
        {
          name:'待返工',
          id:'refuse_wait',
          // componentName:'WorkRework'
        },
      ],
      taskExecuteOps : {
        //对挂起的作业进行领取
        difficult_revise : 'difficult_revise',
        //对已领取或进行中的作业, 继续作业
        fetch : 'fetch',
        //对已提交的作业强制驳回作业
        redo: 'redo',
        //对被驳回的作业进行修改
        refused_revise: 'refused_revise',
        //对返工作业进行领取
        refusesubmit_receive: 'refusesubmit_receive',
      },
      curSubTab:'all',
      // unshow:false,
      // curComponent:'WorkAll',
    }
  },
  props:{
    projectId:{
      default:'',
      type:String
    },
    stepType:{
      default:'',
      type:String
    },
    taskId:{
      default:'',
      type:String
    },
    taskInfo:{
      default:()=>{
        return {}
      },
      type:Object
    },
    subTab:{
      default:'',
      type:String
    },
    unshow:{
      type:Boolean,
      default:false,
    }
  },
  watch:{
    subTab:{
      handler(newV){
        console.log(newV,'newVnewV')
        this.curSubTab = newV;
      },
      immediate:true
    },
    taskInfo:{
      handler(taskInfo){
        if(taskInfo.id){
          this.taskInfo.batch.name = util.base64decode(this.taskInfo.batch.name);
        }
      },
      immediate:true
    },
  },
  computed:{
    resultDataLen(){
      return this.resultData.result && Object.keys(this.resultData.result).length;
    }
  },
  components:{
    ResultView,
    Record
  },
  created(){
    // this.taskId = this.$route.params.id;
    // this.curSubTab = this.$route.params.subtab || 'all';
    // this.unshow = this.$route.params.unshow;
    // this.curComponent = this.subTabList.filter((item)=>{
    //   return item.id == this.curSubTab;
    // })[0].componentName;
    //this.formateWorkTabList(this.stepType);

    this.getList();
  },
  methods:{
    back(){
      this.$emit('back')
    },
    changeSubTab(item){
      this.curSubTab = item.id;
      // this.curComponent = item.componentName;
      // this.$router.push({
      //   name: 'task-detail',
      //   params: {
      //     id: this.$route.params.id,
      //     tab: 'work-list',
      //     subtab: item.id
      //   },
      // });
      this.getList();
    },
    getList(){
      let params = {
        project_id:this.projectId,
        task_id:this.taskId,
        page:this.page,
        pageSize:this.pageSize,
        op:this.curSubTab,
        keyword:this.keyword
      };
      this.loading = true;
      this.api.task.workList(params).then((res)=>{
        this.loading = false;

        this.dataList = res.list;
        this.dataList.forEach((item)=>{
          item.resultLoading = false;
          item.recordLoading = false;
        });
        this.total = Number(res.count);
        this.statusesList = res.statuses;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;

      this.getList();
    },
    showRecord(data){ //操作记录
      this.recordData = {};
      this.getRecordDetail(data);
    },
    getRecordDetail(data){
      let params = {
        project_id:this.projectId,
        data_id:data.data_id
      };
      data.recordLoading = true;
      this.api.production.workRecords(params).then((res)=>{
        data.recordLoading = false;
        this.recordData = res;
        if(!Object.keys(this.recordData.list).length){
          this.$Message.warning(this.$t('project_detail_data_no_record'));
          return;
        }
        this.recordModal = true;
      }).catch((err)=>{
        data.recordLoading = false;
      })
    },
    showResult(data){ //作业结果
      // this.resultModal = true;
      this.resultData = {};
      this.getDataDetail(data);
    },
    getDataDetail(data){
      let params = {
        project_id:this.projectId,
        data_id:data.data_id
      };
      data.resultLoading = true;
      console.log(params,'params')
      this.api.production.dataDetail(params).then((res)=>{
        data.resultLoading = false;
        if(!res.info){
          this.$Message.warning(this.$t('project_detail_data_no_result'));
          return;
        }
        this.resultData = res.info.dataResult;

        if(!Object.keys(this.resultData.result).length){
          this.$Message.warning(this.$t('project_detail_data_no_result'));
          return;
        }
        this.resultModal = true;
      }).catch((err)=>{
        data.resultLoading = false;
      })
    },
    taskExecute (op) {
      let params = {
        project_id: this.curProjectId,
        task_id: this.taskId,
        // user_id: this.userId,
        op: op,
        data_id: this.operatorDataId,
      };
      this.loading = true;
      this.api.task.taskExecute(params).then((res)=>{
        this.loading = false;
        this.$router.push({
          name: 'task-execute',
          query: {
            project_id: this.curProjectId,
            task_id:this.taskId,
            data_id: this.operatorDataId,
          }
        });
      }).catch((err)=>{
        this.loading = false;
      })
    },
    forceReset (op) {
      let params = {
        project_id: this.curProjectId,
        task_id: this.taskId,
        // user_id: this.userId,
        op: op,
        data_id: this.operatorDataId,
      };
      this.loading = true;
      this.api.task.taskExecute(params).then((res)=>{
        this.loading = false;
        this.page = 1;

        this.getList();
      }).catch((err)=>{
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.work-list{
  margin-top: 20px;
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tab{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border:1px solid #dcdee2;
      text-align: center;
      height: 46px;
      border-radius: 4px;
      overflow: hidden;
      font-size: 14px;
      cursor: default;
      line-height: 46px;

      >div{
        width: 100px;
        border-right:1px solid #dcdee2;
        cursor: pointer;
        height: 100%;
      }
      >div:last-child{
        border-right:none;
      }
      >div.active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }


  }
}

.work{
  .search{
    //margin-top: -38px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .search-type{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 10px;
      >span{
        margin-right: 10px;
      }
    }
  }
  .table{
    margin-top: 20px;
  }
  .page{
    margin-top:20px;
    text-align: right;
  }
}

</style>
