<template>
<div class="project-data">
  <div class="search">
    <div>
      <p>{{ $t('project_detail_data_id') }}</p>
      <Input v-model="params.id" :placeholder="$t('project_detail_data_id_placeholder')"  />
    </div>
    <div>
      <p>{{ $t('project_detail_data_name') }}</p>
      <Input v-model="params.name" :placeholder="$t('project_detail_data_name_placeholder')"  />
    </div>
    <div>
      <p>{{ $t('project_detail_data_batch') }}</p>
      <Select v-model="params.batch" filterable style="width:200px" :placeholder="$t('project_detail_data_batch_placeholder')">
        <Option v-for="item in projectData.batches" :value="item.id" :key="item.id">{{ item.name }}</Option>
      </Select>
    </div>
    <div>
      <p>工序</p>
      <Select v-model="params.step" filterable style="width:200px">
        <Option v-for="item in projectData.steps" :value="item.id" :key="item.id">{{ item.name }}</Option>
      </Select>
    </div>
    <div>
      <p>{{ $t('project_detail_data_date') }}</p>
      <DatePicker v-model="params.date" :options="dateOptions" type="datetimerange" :placeholder="$t('project_detail_data_date_placeholder')" format="yyyy-MM-dd" style="width: 200px;"></DatePicker>
    </div>
    <div class="btn">
      <Button class="mr20" @click="resetParam">{{ $t('project_detail_data_reset') }}</Button>
      <Button type="primary" @click="search">{{ $t('project_detail_data_search') }}</Button>
    </div>
  </div>
  <div class="table">
    <Table border ref="selection" :columns="columns" :data="dataList"></Table>
  </div>
  <div class="page">
    <Page :total="total" :current="page" @on-change="changePage" show-total show-sizer show-elevator/>
  </div>
  <Modal
      v-model="resultModal"
      :title="$t('project_detail_data_work_result')"
      width="70%"
  >
    <ResultView :resultData="resultData" v-if="resultDataLen" @success="editSuccess"></ResultView>
    <div slot="footer"></div>
  </Modal>
  <Modal
      v-model="recordModal"
      :title="$t('project_detail_data_op_record')"
  >
    <Record :recordData="recordData"></Record>
    <div slot="footer"></div>
  </Modal>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import ResultView from '@/components/production/category_question/resultEdit.vue'
import Record from '@/components/production/category_question/record.vue'
export default {
  name: "projectData.vue",
  data(){
    return {
      params:{
        id:'',
        name:'',
        batch:'',
        step:'',
        date:[]
      },
      projectData:{},
      columns: [
        {
          title: this.$t('project_detail_data_id'),
          key: 'id'
        },
        {
          title: this.$t('project_detail_data_name'),
          key: 'name',
          tooltip:true,
        },
        {
          title: this.$t('project_detail_data_batch_id'),
          key: 'batch_id'
        },
        {
          title: this.$t('project_detail_data_batch_name'),
          render:(h, params) => {
            return h('div', {},util.decrypt(params.row.batch.name));
          }
        },
        {
          title: this.$t('project_detail_data_sort'),
          key: 'sort'
        },
        {
          title: this.$t('project_detail_data_process'),
          key: 'address',
          render:(h, params) => {
              var steps = [];
              params.row.works.forEach((item, index)=>{
                steps[index] = item.step.name;
              });
              return h('div', {}, steps.join(','));
            // return h('div', [
            //   h('Button', {
            //     props: {
            //       size: 'small',
            //       loading:params.row.recordLoading
            //     },
            //     style: {
            //       marginRight: '5px'
            //     },
            //     on: {
            //       click: () => {
            //         this.showRecord(params.row)
            //       }
            //     }
            //   }, this.$t('project_detail_data_record')),
            // ])
          }
        },
        {

          title: this.$t('project_detail_data_date'),
          render:(h, params) => {
            return h('div', {},util.timeFormatter(
                new Date(+params.row.updated_at * 1000),
                'yyyy-MM-dd hh:mm:ss'
            ));
          }
        },
        {
          title: this.$t('project_detail_data_check'),
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  size: 'small',
                  loading:params.row.recordLoading
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showRecord(params.row)
                  }
                }
              }, this.$t('project_detail_data_record')),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  loading:params.row.resultLoading
                },
                on: {
                  click: () => {
                    this.showResult(params.row)
                  }
                }
              }, this.$t('project_detail_data_result'))
            ]);
          }
        }
      ],
      dataList: [],
      curData:{}, //当前数据
      recordModalStatus:true,  //操作记录弹窗状态
      searchParams:{
        id:'',
        name:'',
        batch:'',
        step:'',
        date:''
      },
      projectId:'',
      loading:false,
      dateOptions:{}, //配置日期的最大时间和最小时间
      page:1,
      pageSize: 10,
      total:0,
      resultModal:false,
      resultLoading:false,
      resultData:{},
      recordModal:false,  //操作记录弹窗
      recordData:{},
      recordLoading:false,
    }
  },
  components:{
    ResultView,
    Record
  },
  computed:{
    resultDataLen(){
      return this.resultData.result && Object.keys(this.resultData.result).length;
    }
  },
  props:{
    project:{
      type:Object,
      default:{}
    }
  },
  watch:{
    project:{
      handler(newV,oldV){
        if(Object.keys(newV).length){
          this.projectData = newV;
          let data = this.projectData;
          this.formateData();
          this.dateOptions = {
            disabledDate (date) {
              let disabled = false;
              if(date.getTime() > Number(data.end_time) * 1000 || date.getTime() < Number(data.start_time) * 1000){
                disabled = true;
              }
              return disabled;
            }
          }
        }

      },
      immediate:true,
    }
  },
  created(){
    this.projectId = this.$route.params.id;
    this.getDataList();
  },
  methods:{
    formateData(){
      this.projectData.batches.forEach((item)=>{
        item.name = util.decrypt(item.name);
      })
    },
    resetParam(){
      this.params = {
        id:'',
        name:'',
        batch:'',
        step:'',
        date:''
      }
    },

    search(){
      this.searchParams.id = this.params.id;
      this.searchParams.name = this.params.name;
      this.searchParams.batch = this.params.batch;
      this.searchParams.step = this.params.step;
      this.searchParams.date = this.params.date;

      // start_time:new Date(this.formItem.date[0]).getTime()/1000,
          // end_time:new Date(this.formItem.date[1]).getTime()/1000
      console.log(this.searchParams,'this.searchParams')
      this.getDataList();

    },
    getDataList(){  //获取数据列表
      let params = {
        project_id:this.projectId,
        keyword:this.searchParams.name,
        page:this.page,
        pageSize:this.pageSize,
        batch_id:this.searchParams.batch,
        step_id:this.searchParams.step,
        data_id:this.searchParams.id,
        update_start_time: new Date(this.searchParams.date[0]).getTime()/1000,
        update_end_time:new Date(this.searchParams.date[1]).getTime()/1000,
      };
      this.loading = true;
      this.api.production.dataList(params).then((res)=>{
        this.loading = false;
        this.dataList = res.list;
        this.dataList.forEach((item)=>{
          item.resultLoading = false;
          item.recordLoading = false;
        });
        this.total = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getDataList();
    },
    showRecord(data){ //操作记录
      this.curData = data;
      this.recordData = {};
      this.getRecordDetail(data);
    },
    getRecordDetail(data){
      let params = {
        project_id:this.projectId,
        data_id:this.curData.id
      };
      data.recordLoading = true;
      this.api.production.workRecords(params).then((res)=>{
        data.recordLoading = false;
        this.recordData = res;
        if(!Object.keys(this.recordData.list).length){
          this.$Message.warning(this.$t('project_detail_data_no_record'));
          return;
        }
        this.recordModal = true;
      }).catch((err)=>{
        data.recordLoading = false;
      })
    },
    showResult(data){ //作业结果
      // this.resultModal = true;
      this.curData = data;

      this.resultData = {};
      this.getDataDetail(data);
    },
    getDataDetail(data){
      let params = {
        project_id:this.projectId,
        data_id:this.curData.id
      };
      data.resultLoading = true;
      this.api.production.dataDetail(params).then((res)=>{
        data.resultLoading = false;
        if(!res.info){
          this.$Message.warning(this.$t('project_detail_data_no_result'));
          return;
        }
        this.resultData = res.info.dataResult;
        if(!Object.keys(this.resultData.result).length){
          this.$Message.warning(this.$t('project_detail_data_no_result'));
          return;
        }
        this.resultModal = true;
      }).catch((err)=>{
        data.resultLoading = false;
      })
    },
    editSuccess(){
      this.resultModal = false;
    }
  }
}
</script>

<style scoped lang="scss">
.project-data{
  .search{
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >div{
      margin-left: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        white-space: nowrap;
        margin-right: 10px;
      }
    }
    .btn{

    }
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
  .mr20{
    margin-right: 20px;
  }
}

</style>
