<template>
  <div class="exam-main">
    <div class="cont">
      <div class="exam-des">
        <div class="l">
          <div>
            <span>{{ $t('task_work_tasks') }}:</span>
            <span>{{ dataObj.data && dataObj.data['工作任务'] }}</span>
          </div>
          <div>
            <span>{{ $t('task_skill_set') }}:</span>
            <span>{{ dataObj.data && dataObj.data['技能集'] }}</span>
          </div>
          <div>
            <span>{{ $t('task_first_level') }}:</span>
            <span>{{ dataObj.data && dataObj.data['技能点（一级知识点）'] }}</span>
          </div>
        </div>
        <div class="r">
          <div>
            <span>{{ $t('task_question_type') }}:</span>
            <span>{{ dataObj.data && dataObj.data['题型'] }}</span>
          </div>
          <div>
            <span>{{ $t('task_difficulty_level') }}:</span>
            <span>{{ dataObj.data && dataObj.data['难度等级'] }}</span>
          </div>
          <div>
            <span>{{ $t('task_knowledge_points') }}:<br />({{ $t('task_newly_build') }})</span>
            <div>
              <el-select
                  v-model="dataObj.result.info.nodes"
                  disabled
                  style="width:300px;"
              >
                <el-option
                    v-for="item in knowledgePointList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="exam-cont">
        <div class="item">
          <div class="item-l">
            <p>*{{ $t('task_test_title') }}</p>
          </div>
          <div class="item-r">
            <div class="item-r-input">
              <div class="item-r-input-cont">
                <div class="left" >
<!--                  {{dataObj.result.info.name}}-->
                  <Richtxt :dataObj="{val:dataObj.result.info.name}" :isInline="true"></Richtxt>

                </div>
              </div>

              <div class="btn">
                <!--              <Button size="small">预览</Button>-->
              </div>
            </div>
            <div class="item-r-comment">
            </div>
          </div>
        </div>
        <div class="item" v-for="(item,index) in dataObj.result.info.options" :key="item.id + 'option'">
          <div class="item-l">
            <p>*{{ nameList[index] }}</p>
            <p :class="item.is_correct ? 'correct' : ''" v-if="item.is_correct">{{item.is_correct ? $t('task_correct_option') : $t('task_set_correct_option')}}</p>
          </div>
          <div class="item-r">
            <div class="item-r-input" :class="item.is_correct ? 'correct' : ''">
              <div class="item-r-input-cont">
                <div class="left">
                  <Richtxt :dataObj="{val:item.name}" :isInline="true"></Richtxt>
                </div>
              </div>

            </div>
            <div class="item-r-comment">
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item-l">
            <p>{{ $t('task_analysis') }}</p>
          </div>
          <div class="item-r">
            <div class="item-r-input">
              <div class="item-r-input-cont">
                <div class="left">
                  <Richtxt :dataObj="{val:dataObj.result.info.explain}" :isInline="true"></Richtxt>
                </div>
              </div>
            </div>
            <div class="item-r-comment">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Richtxt from '../richtxt.vue';
export default {
  name: "check",
  props: {
    resultData:{
      type:Object,
      default:()=>{ return {}}
    }
  },
  watch:{
    resultData:{
      handler(newV){
        this.dataObj = newV
      },
      immediate:true,
    }
  },
  data(){
    return{
      dataObj:{},
      knowledgePointList:[],
      nameList:['选项A','选项B','选项C','选项D','选项E','选项F']
    }
  },
  components:{
    Richtxt
  },
  created(){

  },
  methods:{

  }
}
</script>

<style scoped lang="scss">

.tabInfo-enter-active {
  animation: testanimation 1s;
}
.tabInfo-leave-active {
  animation: testanimation 1s reverse;
}
@keyframes testanimation {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
.exam-main{
  margin: 20px;
  .cont{
    .cont-tit{
      font-weight: bold;
      font-size: 16px;
    }
    .cont-num{
      margin-top: 10px;

      >span{
        display: inline-block;
        font-size: 16px;
        color:#ffffff;
        border-radius: 20px;
        margin: 5px 15px 5px 0;
        width: 40px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background: #808695;
        cursor: pointer;
      }
      .active{
        background: #2d8cf0;
      }
      .success{
        background: #19be6b;
      }
      .reject{
        background: #ed4014;
      }
      .reset{
        background: #ff9900;
      }
    }
    .exam-num{
      margin: 20px 0;
      font-size: 22px;
      font-weight: bold;
      color:#000000;
      >span{
        color: rgb(30, 130, 242);
      }
    }
    .exam-des{
      display: flex;
      justify-content: flex-start;
      align-items: center;

      >div{
        flex: 1;
        >div{
          display: flex;
          justify-content: flex-start;
          margin-bottom: 10px;

          >span:nth-child(1){
            padding-right: 10px;
            width: 80px;
            text-align: right;
          }
        }
      }

      .knowledge-point{
        width: 300px;
        border:1px solid #dcdee2;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .no-bor{
          flex: 1;
          border: none;
          min-width: 20px;

          input{
            border:none !important;
            background-color: transparent !important;
          }
        }
        >input:active,>input:hover{
          border:none;
        }
        >input[type=text]:focus{
          outline:none;
        }
      }
    }
    .exam-cont{
      margin-top: 20px;
      .item{
        display: flex;
        justify-content: flex-start;

        .item-l{
          width: 100px;
          text-align: right;

          >p:nth-child(2){
            margin-top: 5px;
            display: inline-block;
            padding: 5px 10px;
            border:1px solid #dcdee2;
            border-radius: 4px;
            cursor: pointer;
          }
          >p.correct{
            border:1px solid #19be6b;
            background-color: #19be6b;
            color:#FFFFFF;
          }
        }
        .item-r{
          margin-left: 20px;
          flex: 1;

          .item-r-input{
            min-height: 80px;

            border:1px solid #dcdee2;
            border-radius: 4px;
            position: relative;

            .item-r-input-cont{
              display: flex;
              justify-content: flex-start;
              min-height: 80px;

              .left,.right{
                flex: 1;
              }
              .right{
                border-left:1px solid #dcdee2;
              }
            }
            .btn{
              display: flex;
              flex-flow: column;
              position: absolute;
              right: 10px;
              top: 10px;
            }


          }
          .item-r-input.correct{
            border:2px solid #2d8cf0;
          }
          .item-r-comment{
            margin-top: 10px;
            margin-bottom: 10px;
            color:red;
            line-height: 22px;
          }

        }

      }
      .add-btn{
        margin-bottom: 20px;
      }
    }
    .bot{
      margin-top: 40px;
      margin-bottom: 40px;
      padding-top: 20px;
      border-top: 1px solid #dcdee2;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .bot-btn{
        margin-right: 20px;
      }
    }
  }

}
.mr{
  margin-right: 10px;
}
.timeout-text{
  font-size: 14px;
}
</style>
<style lang="scss">
.no-bor{
  flex: 1;
  border: none;
  min-width: 20px;

  input,
  input:focus,
  input:active,
  input:hover{
    border:none !important;
    background-color: transparent !important;
    box-shadow: none;
  }
}
.comment{
  .ivu-input{
    height: 235px !important;
    width: 100% !important;
    border:none !important;
  }
}
</style>
