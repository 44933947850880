<template>
<div class="project-info">
  <div class="data">
    <div>
      <div class="tit">{{ $t('project_detail_info_batch_num') }}</div>
      <div class="num">{{project.batch_count}}</div>
    </div>
    <div>
      <div class="tit">{{ $t('project_detail_info_step_num') }}</div>
      <div class="num">{{project.step_count}}</div>
    </div>
    <div>
      <div class="tit">{{ $t('project_detail_info_work_num') }}</div>
      <div class="num">{{project.amount}}</div>
    </div>
    <div>
      <div class="tit">{{ $t('project_detail_info_work_person') }}</div>
      <div class="num">{{project.join_user_count}}</div>
    </div>
  </div>

  <div class="table">
    <Table :columns="columns" :data="dataList"></Table>
  </div>
  <p class="flow-chart-tit">{{ $t('project_detail_info_flow') }}</p>
  <div class="flow-chart">

    <div v-for="(item,index) in project.steps" :key="index + 'check'">
      <div class="item">
        <p class="tit" :style="{color:item.color,border:`1px solid ${item.color}`}">{{ item.name }}</p>
        <div class="fgx" v-if="(index + 1) < project.steps.length">
          <p></p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
  <p class="flow-chart-tit">{{ $t('project_detail_info_attention') }}</p>
  <div class="des" v-if="project.attr">
<!--    {{project.attr.description || ''}}-->
    <Richtxt v-if="project.attr.description" :isDisabled="true" :dataObj="{val:project.attr.description}" ></Richtxt>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import progressOp from '@/components/progress';
import Richtxt from '@/components/production/richtxt.vue';
export default {
  name: "projectInfo.vue",
  props:{
    project:{
      type:Object,
      default:{}
    }
  },
  components:{
    progressOp,
    Richtxt
  },
  watch:{
    project:{
      handler(newV,oldV){
        if(Object.keys(newV).length){
          this.projectData = newV;
          this.formateData();
        }

      },
      immediate:true,
    }
  },
  data(){
    return {
      columns: [],
      dataList: [],
      projectData:{},
    }
  },
  methods:{
    formateData(){
      this.formateColumn(this.projectData.steps);
      this.columns = this.formateColumn(this.projectData.steps);
      this.dataList = this.formateTableData(this.projectData.batches, this.projectData.steps);
    },
    formateColumn(data) {
      console.log(data,'data')
      data = data.map((item, index) => {
        return {
          title: item.name,
          key: 'index' + index,
          render: (h, para) => {
            let progress1 = this.getProcess1(para.row['index' + index],para.row);
            let progress2 = this.getProcess2(para.row['index' + index],para.row);
            return h('div', [
              h('div', [
                h('div', [
                  h(progressOp, {
                    props: {
                      progress1: progress1,
                      progress2: progress2
                    },
                    style:{
                      marginTop:'10px',
                    }
                  }),
                  h('div', {
                    style:{
                      margin:'5px 0 10px 0',
                    }
                  },'已执行:'+(para.row['index' + index].stat.work_count ? para.row['index' + index].stat.work_count : '0') + ' / '
                  + '可执行:'+(para.row['index' + index].stat.amount ? para.row['index' + index].stat.amount : 0) + ' / '
                  + '总量:'+(para.row.amount ? para.row.amount : 0)),
                ])
                // h('Tooltip', {
                //   props: {
                //     content: (para.row['index' + index].stat.work_count ? para.row['index' + index].stat.work_count : '0') + ' / '
                //       + (para.row['index' + index].stat.amount ? para.row['index' + index].stat.amount : 0) + ' / '
                //       + (para.row.amount ? para.row.amount : 0),
                //     placement: 'top-start',
                //     transfer: true
                //   },
                //   'class': 'tool_tip',
                //   style: {
                //     //display: 'inline'
                //     width: '100%'
                //   }
                // }, [
                //
                //
                // ]),
              ]),
            ]);
          },
          renderHeader: (h, params) => {
            return h('span', [
              h('Poptip', {
                'class': {
                  tablePop: true,
                },
                props: {
                  trigger: "hover",
                  title: this.$t('admin_fields_description'),
                  transfer: true,
                  placement: 'right-start',
                },
                scopedSlots: {
                  content: () => {
                    return h('span', {
                    }, [
                      h('div', this.$t('admin_schedule_execute_description')),
                      h('div', this.$t('admin_schedule_audit_description')),
                      h('div', this.$t('admin_schedule_qc_description')),
                    ]);
                  }
                }
              }, [
                h('span', item.name),
                h('Icon', {
                  style: {
                    marginLeft: '3px',
                    marginTop: '1px',
                    verticalAlign: 'top'
                  },
                  props: {
                    type: 'ios-help-circle-outline',
                    size: 16
                  },
                }),
              ])
            ]);
          }
        }
      });
      let column = [
        {
          title: this.$t('project_create_personnel_batch_name'),
          key: 'name'
        },
        {
          title: this.$t('project_create_personnel_batch_num'),
          key: 'amount'
        },
        ...data,
      ];
      return column;
    },
    getProcess1 (row,data) {
      let work_count = row.stat !== "" ? row.stat.work_count : 0;
      let batchAmount = data.amount * 1;
      if (!batchAmount || (batchAmount === 0)) {
        return 0;
      }
      return +(work_count / batchAmount * 100).toFixed(2);
    },
    getProcess2 (row,data) {
      let taskAmount = row.stat.amount * 1;
      let batchAmount = data.amount * 1;
      return +(taskAmount / batchAmount * 100).toFixed(2);
    },
    formateTableData(data, steps) {
      data.forEach((item) => {
        item.name = util.decrypt(item.name)
        steps.forEach((sItem, index) => {
          let taskData = item.tasks.filter((task) => {
            return task.step_id == sItem.id;
          })[0];
          item['index' + index] = taskData;
        })
      });
      return data;
    },
  },
}
</script>

<style scoped lang="scss">
.project-info{
  .data{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

  >div{
    padding: 20px;
    flex: 1;
    margin-right: 20px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);

    .tit{
      font-size: 14px;
    }
    .num{
      font-size: 20px;
    }
    }
    >div:last-child{
      margin-right: 0;
    }
  }
  .table{
    margin-top: 20px;
    //max-height: 500px;
    //overflow-y: auto;//表格过长, 显示右侧滚动条
  }
  .flow-chart-tit{
    padding-top: 20px;
    font-size: 16px;
  }
  .flow-chart{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: wrap;

    .item{
      margin-top: 20px;
      text-align:center;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .tit{
        margin: 0 auto;
        width: 120px;
        text-align: center;
        height: 60px;
        line-height: 60px;
        border:1px solid #19be6b;
        font-size: 16px;
        font-weight: bold;
        border-radius: 10px;
      }
      .fgx{
        margin-left: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >p:nth-child(1){
          margin: 0 auto;
          height: 2px;
          width: 40px;
          color:#808695;
          border-top: 2px solid #808695;
        }
        >p:nth-child(2){
          margin: 0 auto;
          width: 0;
          height: 0;
          border-width: 10px;
          border-style: solid;
          border-color:#808695 transparent transparent transparent;
          transform: rotate(270deg);
        }
      }
    }
  }
  .des{
    margin: 20px 0;
    width: 100%;
    min-height: 80px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
  }
}
</style>
