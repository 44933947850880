<template>
  <div>
    <div style="margin: 20px 0 0px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="project-detail">
      <div class="layout-content" v-if="!loading">
        <Row>
          <i-col span="2">
            <img v-if="project.category" :src="constant.STATIC_URL + project.category.thumbnail" style="width: 70%;"/>
          </i-col>
          <i-col span="22" v-if="project.category" :push="project.category.thumbnail ? 0 : 2">
            <Row>
              <i-col span="24" class="project-detail-info">
                <div class="info-item" >
                  <strong style="font-size:16px; max-width: 600px; display: inline-block;padding-left: 10px;">{{project.name}}</strong>
                  <div @click="goCreate()" v-if="showStr" :style="{background:statusColor}" class="tit-tag"  style="margin-left: 10px; vertical-align: top;" >{{showStr}}</div>
                </div>
              </i-col>
            </Row>
            <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
              <i-col span="6">ID :   {{project.id}}</i-col>
              <i-col span="6">{{$t('project_projecy_type')}} :   {{project.category.name}}</i-col>
              <i-col span="6">{{$t('project_create_info_person')}} :   {{project.leaderUser.nickname}}</i-col>
              <i-col span="6">{{$t('project_project_status')}} :   {{showStr}}</i-col>
            </Row>
            <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
              <i-col span="6">{{$t('project_starting_time')}} :   {{start_time}}</i-col>
              <i-col span="6">{{$t('project_finish_time')}} :   {{end_time}}</i-col>
            </Row>
          </i-col>
        </Row>
      </div>
      <div class="tab-cont-top" v-if="!loading">
        <div class="tab-top">
          <div>
            <div :class="currentIndex == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{item.name}}</div>
          </div>
        </div>
        <div class="tab-cont">
          <component :is="curComponent" :project="JSON.parse(JSON.stringify(project))"></component>
        </div>
      </div>

      <Spin fix v-if="loading"></Spin>
    </div>
  </div>

</template>

<script>
import util from '@/utils/tools.js';
import ProjectInfo from './components/detail/projectInfo';
import ProjectData from './components/detail/projectData';
import ProjectStat from './components/detail/projectStat';
import ProjectExport from './components/detail/projectExport';
import ProjectRecord from './components/detail/projectRecord';
import ProjectMerits from './components/detail/projectMerits';
import ProjectManage from './components/detail/projectManage';

export default {
    name: 'project-detail',
    data () {
        return {
          currentIndex: 1,//当前tab项
          tableData: [],
          start_time: '',
          end_time: '',
          project: {},
          statuses: {},
          showStr: '',
          statusColor: '',
          tabList:[
            {
              name:this.$t('project_project_details'),
              id:1,
              componentName:'ProjectInfo',
              tabName:'info'
            },
            {
              name:this.$t('project_detail_data'),
              id:2,
              componentName:'ProjectData',
              tabName:'data'
            },
            {
              name:'项目任务',
              id:3,
              componentName:'ProjectManage',
              tabName:'manage'
            },
            {
              name:'项目绩效',
              id:4,
              componentName:'ProjectMerits',
              tabName:'merits'
            },
            // {
            //   name:this.$t('project_detail_stat'),
            //   id:3,
            //   componentName:'ProjectStat',
            //   tabName:'stat'
            // },
            {
              name:this.$t('project_detail_export'),
              id:5,
              componentName:'ProjectExport',
              tabName:'export'
            },
            // {
            //   name:this.$t('project_detail_op'),
            //   id:5,
            //   componentName:'ProjectRecord',
            //   tabName:'record'
            // }
          ],
          loading:false,
          projectId:'',
          currentTab:'',
          curComponent:'',
        };
    },
    watch: {
        '$route.params' () {
            this.currentTab = this.$route.params.tab || 'info';
            this.curComponent = this.tabList.filter((item)=>{
              return item.tabName == this.currentTab
            })[0].componentName;

            this.currentIndex =  this.tabList.filter((item)=>{
              return item.tabName == this.currentTab
            })[0].id;
        },

    },
    created(){
        this.projectId = this.$route.params.id;
        this.currentTab = this.$route.params.tab;
        this.curComponent = this.tabList.filter((item)=>{
          return item.tabName == this.currentTab
        })[0].componentName;

        this.currentIndex =  this.tabList.filter((item)=>{
          return item.tabName == this.currentTab
        })[0].id;
        console.log(this.curComponent,'curComponent')
    },
    methods: {
      getDetail() {
        let data = {
          project_id: this.$route.params.id,
        }
        this.loading = true;
        this.api.production.projectDetail(data).then((res) => {
          this.loading = false;
          this.project = res.project;
          this.start_time = util.timeFormatter(
              new Date(+res.project.start_time * 1000),
              'yyyy-MM-dd'
          );
          this.end_time = util.timeFormatter(
              new Date(+res.project.end_time * 1000),
              'yyyy-MM-dd'
          );
          this.user_name = res.project.user.email;

          this.statuses = res.statuses;
          this.showStatuse(res.project.status);
        });
      },
      showStatuse (status) {
          this.showStr = this.statuses[status];
          if (status == '0') {         // 发布中
              this.statusColor = '#5cadff';
          } else if (status == '1') {  // 配置中
              this.statusColor = '#2d8cf0';
          } else if (status == '2') {  // 作业准备中
              this.statusColor = '#999999';
          }else if (status == '3') {  // 作业中
              this.statusColor = '#19be6b';
          } else if (status == '4') {  // 已暂停
              this.statusColor = '#ed3f14';
          } else if (status == '6') {  // 完成
              this.statusColor = '#ff9900';
          } else if (status == '7') {
              this.statusColor = 'red';
          }
      },
      changeTab(data){
        if(data.id == this.currentIndex) return;
        this.currentIndex = data.id;
        this.curComponent = data.componentName;

        this.$router.push({
          name:'project-detail',
          params:{
            id:this.projectId,
            tab:data.tabName,
          }
        })
      },
      goCreate(){ //继续发布
        if(this.project.status == '0'){
          this.$router.push({
            path: '/production/project-create',
            query: {
              id: this.$route.query.id,
              type:'continue'
            }
          });
        }

      }
    },
    mounted () {
        this.getDetail();
    },
  components:{
    ProjectInfo,
    ProjectData,
    ProjectStat,
    ProjectExport,
    ProjectRecord,
    ProjectMerits,
    ProjectManage
  }
};
</script>

<style scoped lang="scss">
.project-detail{
  .layout-content{
    margin: 20px;
    padding: 20px;
    background: #ffffff;
    min-height: 100px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);

    .project-detail-info {
      display: flex;
      justify-content: space-between;
    }
    .project-detail-info .info-item button {
      margin-bottom: 5px;
    }
    .project-detail-info .info-item button + button {
      margin-left: 5px;
    }
    .info-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tit-tag{
        padding: 5px 10px;
        text-align: center;
        color:#FFFFFF;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .tab-cont-top{
    margin: 0 20px 20px 20px;
    //padding: 20px;
    background: #ffffff;
    //min-height: 180px;
    .tab-top{
      margin: 20px 0 0 20px;
      display: inline-block;
      border:1px solid #dcdee2;
      height: 46px;
      border-radius: 4px;
      >div{
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        overflow: hidden;
        font-size: 14px;
        cursor: default;

        >div{
          width: 200px;
          border-right:1px solid #dcdee2;
          cursor: pointer;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        >div:last-child{
          border-right:none;
        }
        >div.active{
          background-color: #2d8cf0;
          color:#FFFFFF;
        }
      }



    }
  }
  .tab-cont{
    //margin: 0 20px 20px 20px;
    padding: 1px 20px 20px;
    //background: #ffffff;
    //min-height: 180px;
    //border-radius: 4px;
    //box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);


    .tab-data{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      >div{
        padding: 20px;
        flex: 1;
        margin-right: 20px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);

        .tit{
          font-size: 14px;
        }
        .num{
          font-size: 20px;
        }
      }
      >div:last-child{
        margin-right: 0;
      }
    }
  }
}

</style>
