<template>
<div>
    <div class="pro-main borderRadius">
        <p :style="{width: progress2 + '%', height: '10px', background: 'skyblue', position: 'absolute', left: 0, zIndex: 2, display: 'inline-block'}" class="borderRadius"></p>
        <p :style="{width: progress1 + '%', height: '10px', background: getColor(), position: 'absolute', left: 0, zIndex: 3, display: 'inline-block'}" class="borderRadius"></p>
    </div>
    <span style="margin-left: 2px;font-size: 12px;">{{progress1}}%</span>
</div>

</template>
<script>
export default {
    props: {
      progress1:{
        type:Number,
        default:0
      },
      progress2:{
        type:Number,
        default:0
      }
    },
    data () {
        return {

        };
    },
    methods: {
        getColor () {
            if ((this.progress1 === 100) && (this.progress2 === 100)) {
                return '#52C41A';
            }
            if ((this.progress1 !== 100) && (this.progress2 === this.progress1)) {
                return '#FFBF00';
            } else {
                return '#1890FF';
            }
        }
    }
};
</script>
<style scoped>
.pro-main {
    position: relative;
    background: #eeeeee;
    width: 65%;
    height: 10px;
    display: inline-block;
}
.borderRadius {
    border-radius: 6px;
}
</style>


